<template>
  <div class="main">
    <slot />
  </div>
</template>

<style scoped>
.main {
  flex: 1;
  width: 100%;
}
</style>
